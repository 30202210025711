.loading-mask {
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 111;
    .loading {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 100px;
            height: 100px;
        }
    }
}


.show{
    display: block !important;
}


@media screen and (max-width:540px) {
    .loading-mask{
        .loading{
            img{
                width: 100px;
                height: 100px;
            }
        }
    }
}