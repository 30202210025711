.contact-page {
  height: 100%;
  position: relative;
  background-color: rgba(243, 244, 246, 0.5);
  padding: 0 10px;
  .sendEmail {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top: 1px solid rgb(243, 244, 246);
    .image-file {
      position: relative;
      .file-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }
    .input-text {
      width: 250px;
      height: 40px;
      border-radius: 5px;
      background-color: #fff;
      border: 1px solid rgb(243, 244, 246);
      padding: 0 10px;
      &:focus {
        outline: none;
      }
    }
    .send-btn {
      background-color: #6b7280b3;
      color: #fff;
      padding: 10px 15px;
      border-radius: 5px;
    }
  }
  .send-box {
    height: calc(100% - 70px);
    padding: 0 0 80px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    .server-title {
      text-align: center;
      padding: 10px 0;
      font-size: 12px;
      color: rgb(100, 116, 139);
      font-weight: 500;
    }
    .left-message {
      display: flex;
      margin: 16px 0 0;
      .avatar {
        width: 30px;
        height: 30px;
        border-radius: 25px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        img {
          max-width: 100%;
        }
      }
      .text-box {
        background-color: #fff;
        border-radius: 10px;
        border-top-left-radius: 0;
        max-width: 310px;
        padding: 10px;
        box-shadow: 0 1px 2px 0 rgb(0, 0, 0, 0.05);
        line-height: 25px;
        font-size: 15px;
        span {
          color: #ff0000b3;
          font-weight: 500;
        }
        .click-btn {
          background-color: rgb(34, 197, 94);
          color: #fff;
          margin: 10px auto 0;
          padding: 5px;
          border-radius: 5px;
          text-align: center;
          font-weight: 500;
        }
      }
    }
    .right-message {
      display: flex;
      margin: 16px 0 0;
      justify-content: end;
      .avatar {
        width: 30px;
        height: 30px;
        border-radius: 25px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        img {
          max-width: 100%;
        }
      }
      .text-box {
        border-radius: 10px;
        border-top-right-radius: 0;
        max-width: 310px;
        padding: 10px;
        box-shadow: 0 1px 2px 0 rgb(0, 0, 0, 0.05);
        line-height: 25px;
        font-size: 15px;
        background: linear-gradient(to right, #fd9531, #ff5900);
        color: #fff;
        position: relative;
        .icon {
          position: absolute;
          bottom: 0;
          left: -25px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
