.customer-page {
  .shipping-cart {
    width: 700px;
    margin: auto;
    background-color: #f9f9f9;
    height: 56px;
    border-bottom: 1px solid rgba(217, 217, 217, 1);
    border-top: 1px solid rgba(217, 217, 217, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    color: #6ebc2a;
    .left {
      display: flex;
      align-items: center;
      p {
        padding: 0 10px;
      }
    }
  }
  .shoppingCart-content {
    width: 700px;
    margin: auto;
    background-color: #f9f9f9;
    display: none;
    padding: 0 20px;
    .product-row {
      display: flex;
      justify-content: space-between;
      padding: 30px 0 20px;
      border-bottom: 1px solid rgb(229, 231, 235);
      .left {
        display: flex;
        .img-box {
          width: 64px;
          height: 64px;
          border: 1px solid #6ebc2a;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          position: relative;
          img {
            min-width: 100%;
          }
          .num {
            position: absolute;
            top: -10px;
            right: -10px;
            width: 20px;
            height: 20px;
            font-size: 12px;
            border-radius: 15px;
            background-color: #6ebc2a;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .title {
          max-width: 250px;
          margin-left: 10px;
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 18px;
          font-weight: 500;
        }
        .sku {
          color: rgb(117, 117, 117);
          font-size: 13px;
          line-height: 18px;
        }
      }
      .price {
        font-size: 14px;
        font-weight: 600;
      }
    }
    .subtotal-row {
      padding: 20px 0;
      border-bottom: 1px solid rgb(229, 231, 235);
      .top-row {
        display: flex;
        justify-content: space-between;
        color: #888888;
        font-size: 14px;
        margin: 0 0 10px;
        .title {
          display: flex;
          align-items: center;
        }
        .price {
          color: #333;
        }
      }
      .bottom-row {
        display: flex;
        justify-content: space-between;
        color: #888888;
        font-size: 14px;
        .title {
          display: flex;
          align-items: center;
        }
        .price {
          color: #333;
        }
      }
    }
    .total-row {
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      .price {
        font-size: 24px;
        span {
          font-size: 12px;
          font-weight: normal;
          padding: 0 10px;
        }
      }
    }
  }
  .show-cart {
    display: block !important;
  }
  .customer-box {
    width: 700px;
    margin: 66px auto;
    padding-bottom: 100px;
    .title {
      font-size: 22px;
      font-weight: 600;
      color: rgb(92, 94, 98);
    }
    .info-box {
      .input-row {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        .half {
          width: 49%;
          .row {
            width: 100%;
            .tip-title {
              color: #5c5e62;
              font-size: 14px;
              font-weight: 500;
              margin: 0 0 10px;
            }
            .input {
              width: 100%;
              border: 0;
              border-radius: 5px;
              height: 40px;
              background-color: #fff;
              border: 1px solid #d9d9d9;
              padding: 6px 12px;
              &:focus {
                outline: none;
                background-color: #fff;
                border: 3px solid #6ebc2a;
              }
            }
            .tip {
              display: inline-block;
              margin: 10px 0 0;
              font-size: 12px;
              font-weight: 500;
              color: rgb(255, 0, 0);
            }
            .phone {
              width: 100%;
            }
            .react-tel-input {
              width: 100%;
              height: 40px;
              box-shadow: 0;
              input {
                width: 100%;
                height: 42px;
                border: 0;
                font-size: 14px;
                overflow: hidden;
                background-color: #fff;
                box-shadow: none;
                border: 1px solid #d9d9d9;
                padding-left: 60px;
                &::placeholder {
                  font-size: 14px;
                }
                &:focus {
                  outline: none;
                }
              }
              .selected-flag {
                border: 0;
                background-color: transparent;
                left: 9px;
                transform: scale(1.5, 1.5);
                top: 5px;
              }
            }
          }
        }
        .full {
          width: 100%;
          .row {
            width: 100%;
            .tip-title {
              color: #5c5e62;
              font-size: 14px;
              font-weight: 500;
              margin: 0 0 10px;
            }
            .input {
              width: 100%;
              border: 0;
              border-radius: 5px;
              height: 40px;
              background-color: #fff;
              border: 1px solid #d9d9d9;
              padding: 6px 12px;
              &:focus {
                outline: none;
                background-color: #fff;
                border: 3px solid #6ebc2a;
              }
            }
          }
        }

        .tip {
          display: inline-block;
          margin: 10px 0 0;
          font-size: 15px;
          font-weight: 500;
          color: rgb(223, 44, 44);
        }
        .btn {
          width: 100%;
          margin: 20px 0 0;
          padding: 10px;
          background-color: #6ebc2a;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-weight: 500;
          border-radius: 5px;
          border: 1px solid rgb(229, 231, 235);
          opacity: 0.35;
          &:hover {
            cursor: not-allowed;
          }
        }
        .check {
          width: 100%;
          margin: 20px 0 0;
          padding: 10px;
          background-color: #6ebc2a;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-weight: 500;
          border-radius: 5px;
          border: 1px solid #6ebc2a;
          &:hover {
            cursor: pointer;
          }
        }
        .search-btn {
          width: 30%;
          border-radius: 5px;
          height: 40px;
          padding: 6px 12px;
          background-color: #6ebc2a;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            padding: 0 10px;
          }
        }
        .search-url {
          width: 65%;
          a {
            width: 100%;
            display: flex;
            height: 40px;
            justify-content: space-between;
            align-items: center;
            text-decoration: none;
            p {
              font-size: 13px;
              color: #5c5e62;
            }
          }
        }
      }
      .next-model {
        .permission {
          display: none;
          .permission-row {
            margin: 10px 0;
            display: flex;
            align-items: center;
            color: #5c5e62;
            font-size: 12px;
            font-weight: 500;
            .MuiCheckbox-colorPrimary.Mui-checked {
              color: #6ebc2a;
            }
            .MuiIconButton-root {
              padding: 0;
              margin-right: 10px;
            }
          }
        }
        .show-permission {
          display: block !important;
        }
      }
      .cancel-btn {
        margin: 30px auto;
        padding: 10px;
        // background-color: #12192c;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #6ebc2a;
        font-weight: 500;
        border-radius: 5px;
        border: 1px solid #6ebc2a;
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
      .choose-row {
        margin: 10px 0;
        h2 {
          font-size: 20px;
          font-weight: 600;
          color: rgb(92, 94, 98);
        }
        .choose-li {
          display: flex;
          align-items: center;
          border: 1px solid rgb(235, 231, 229);
          border-radius: 5px;
          padding: 10px 15px;
          margin: 20px 0;
          font-size: 14px;
          font-weight: 500;
          color: rgb(57, 60, 65);
          line-height: 20px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.ant-radio-checked {
  .ant-radio-inner {
    border-color: #6ebc2a !important;
    background-color: #6ebc2a !important;
  }
}

@media screen and (max-width: 768px) {
  .customer-page {
    .shipping-cart{
      width: 100%;
    }
    .shoppingCart-content{
      width: 100%;
    }
    .customer-box {
      width: 100%;
      padding: 0 20px 50px;
      margin: 20px 0;
      .title {
        margin: 0 0 20px;
      }
      .info-box {
        .input-row {
          margin: 0;
          .half {
            width: 100%;
            margin: 0 0 20px;
          }
          .full {
            margin: 0 0 20px;
          }
          .search-btn {
            width: 100%;
            margin: 0 0 20px;
          }
          .search-url {
            width: 100%;
            margin: 0 0 20px;
          }
        }
        .next-model {
          .country-box {
            margin: 40px 0 0;
          }
        }
      }
    }
  }
}
