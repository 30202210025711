.fail-page {
  width: 800px;
  margin: 40px auto;

  .head-logo {
    display: flex;
    justify-content: center;
    height: 30px;
    margin: 0 0 10px;
    img {
      max-height: 100%;
    }
  }

  p {
    margin: 20px 0;
    line-height: 25px;
    text-align: center;
    font-weight: 500;
    span {
      color: rgb(0, 47, 255);
    }
  }
  .pro{
    height: 150px;
    display: flex;
    justify-content: center;

    img{
      max-height: 100%;
    }
  }

  .tip {
    color: #3a3a3a !important;
    font-size: 20px;
    line-height: 1.5;
  }
  .icon-row {
    margin: 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      width: 60px;
      height: 60px;
      border-radius: 50px;
      background-color: rgb(61, 189, 89);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .li-icon {
      width: 200px;
      img {
        max-width: 100%;
      }
    }
  }
  .loading-line {
    margin: 40px 0;
    width: 100%;
    height: 25px;
    background-color: #fff;
    box-shadow: 5px 3px 7px 0px rgba(0, 0, 0, 0.125);
    border-radius: 25px;
    position: relative;
    padding: 10px;
    border: 2px solid rgb(229, 231, 235);
    .bgc-line {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      padding: 10px;
      background-color: #6ebc2a;
      border-radius: 25px;
      overflow: hidden;
      transition: all 0.3s;
      display: flex;
      justify-content: end;
      align-items: center;
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      padding: 0 10px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          45deg,
          rgba(255, 255, 255, 0.2) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0.2) 75%,
          transparent 75%,
          transparent 100%
        );
        background-size: 40px 40px;
        animation: stripes 1s linear infinite;
      }
    }

    .w20 {
      width: 20%;
    }
    .w40 {
      width: 40%;
    }
    .w60 {
      width: 60%;
    }
    .w80 {
      width: 80%;
    }
    .w100 {
      width: 100%;
    }
  }
  .btn {
    margin: 30px auto;
    padding: 10px;
    background-color: #6ebc2a;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 500;
    border-radius: 5px;
    font-size: 14px;
    border: 1px solid rgb(229, 231, 235);
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

.share-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 5;
  .share-textBox {
    width: 300px;
    // height: 150px;
    background-color: #fff;
    z-index: 2;
    border-radius: 15px;
    box-shadow: 2px 6px 19px 11px rgba(0, 0, 0, 0.125);
    padding: 30px;
    position: relative;
    .title {
      font-size: 24px;
      color: rgb(238, 38, 71);
      font-weight: 600;
      text-align: center;
      margin: 0 0 15px;
    }
    .desc {
      font-size: 15px;
      line-height: 25px;
      text-align: center;
      font-weight: 600;
    }
    .lihua-icon {
      position: absolute;
      top: -37px;
      left: -20px;
      transform: rotate(342deg);
    }
    .close-icon{
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  .sahua {
    position: absolute;
    top: 30%;
    left: 0;
    width: 100%;
    z-index: 2;
  }
}

.closeIcon {
  position: absolute;
  bottom: -80px;
  left: 50%;
  transform: translateX(-50%);
}

.show {
  display: flex !important;
}

@keyframes stripes {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 40px 0;
  }
}

@media screen and (max-width: 800px) {
  .fail-page {
    padding: 0 20px;
    width: 100%;
  }
}

@media screen and (max-width: 430px) {
  .fail-page {
    .icon-row {
      .icon {
        width: 40px;
        height: 40px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .fail-page {
    .icon-row {
      .icon {
        width: 35px;
        height: 35px;
      }
    }
  }
}
