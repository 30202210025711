@font-face {
  font-family: Jin;
  font-weight: 400;
  src: url("./fonts/JinNan.otf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  /* color: rgb(65, 71, 85); */
}
/* body {
  margin: 0;
  font-family: Jin;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  color: rgb(65, 71, 85);
} */


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  position: relative;
}

body,
html,
#root {
  height: 100%;
}

/* ul,li{
  list-style: none;
  margin: 0;
  padding: 0;
} */