.model-page {
  overflow: hidden;
  .model-box {
    width: 700px;
    margin: 66px auto 0;
    .title {
      font-size: 38px;
      font-weight: 600;
      color: #393c41;
    }
    .detail {
      font-size: 15px;
      line-height: 1.5;
      margin: 10px 0 0;
      font-weight: 500;
      color: rgb(57, 60, 65);
    }
    .choose-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 20px 0 0;
      .left {
        li {
          margin: 0 0 25px;
          border: 1px solid rgb(162, 163, 165);
          border-radius: 10px;
          padding: 15px 10px;
          display: flex;
          justify-content: center;
          color: #393c41;
          font-weight: 500;
          min-width: 300px;
          font-size: 14px;
        }
        .active {
          border: 2px solid #6ebc2a;
          color: #6ebc2a;
        }
        .disabled {
          li {
            color: #acacac;
            border: 1px solid #acacac;
            opacity: 0.7;
            &:hover {
              cursor: not-allowed;
            }
          }
        }
      }
      .right {
        margin-left: 50px;
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 50px;
        img {
          max-width: 100%;
          max-height: 100%;
          border-radius: 10px;
          box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
            0 8px 10px -6px rgba(0, 0, 0, 0.1);
        }
      }
    }
    .btn {
      width: 300px;
      margin: 30px auto 0;
      padding: 15px;
      background-color: #6ebc2a;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: 600;
      border-radius: 10px;
      border: 1px solid rgb(229, 231, 235);
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .model-page {
    overflow-y: scroll;
    .model-box {
      width: 100%;
      padding: 0 20px 20px;
      margin: 20px 0 0;
      .title {
        font-size: 22px;
      }
      .detail{
        font-size: 14px;
      }
      .choose-box {
        justify-content: center;
        align-items: center;
        .right {
          width: 100%;
          margin-left: 0;
          // height: 250px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px){
.model-page{
  .model-box{
    .btn{
      width: 100%;
    }
  }
}
}