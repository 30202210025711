.header-box{
    margin: 30px 0 0; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 35px;
    .logo{
        width: 40px;
        height: 40px;
        img{
            max-width: 100%;
            max-height: 100%;
        }
    }
    .allowLeft{
        &:hover{
            cursor: pointer;
        }
    }
    .right{
        width: 20px;
    }
}


@media screen and (max-width:768px) {
    .header-box{
        padding: 0 20px;
    }
}