.selfie-page{
    overflow: hidden;
    .selfie-box{
        width: 700px;
        margin: 40px auto;
        h1 {
            text-align: center;
            color: #393c42;
            font-size: 32px;
        }
        h2 {
            margin: 30px 0 0;
            text-align: center;
            color: #393c42;
            font-size: 17px;
            font-weight: normal;
        }
        .gif-box {
            margin: 50px 0 0;
            img {
                max-width: 100%;
            }
        }
        .btn {
            width: 300px;
            margin: 50px auto 0;
            padding: 10px;
            background-color: #12192c;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-weight: 500;
            border-radius: 10px;
            border: 1px solid rgb(229, 231, 235);
            &:hover {
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width:700px) {
    .selfie-page{
        .selfie-box{
            width: 100%;
            padding: 0 20px;
        }
    }
    
}