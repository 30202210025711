.order-page {
  .order-box {
    width: 800px;
    margin: 40px auto;
    h1 {
      font-size: 32px;
      font-weight: 500;
      color: rgb(92, 94, 98);
    }
    .table {
      padding: 40px 0 20px;
      .head {
        height: 50px;
        padding: 15px 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: rgb(92, 94, 98);
        font-weight: 500;
        color: #fff;
      }
      .table-li {
        border: 1px solid #e5e7eb;
        display: flex;
        align-items: center;
        border-top: 0px;
        .title {
          width: 250px;
          padding: 15px 20px;
          font-weight: 600;
          display: flex;
          flex-direction: column;
          justify-content: center;
          color: rgb(92, 94, 98);
        }
        .content {
          padding: 15px 20px;
          font-size: 15px;
          color: #393c41;
          font-weight: 400;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border-left: 1px solid #e5e7eb;
          min-height: 80px;
          img {
            max-width: 300px;
          }
          video {
            max-width: 300px;
          }
        }
      }
      .upload-row {
        margin: 15px 0;
        .upload {
          color: #393c41;
          font-weight: 500;
          text-decoration: underline;
          font-style: italic;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .choose-box {
      padding: 40px 0 100px;
      h2 {
        font-size: 30px;
        font-weight: 500;
        color: rgb(92, 94, 98);
      }
      .choose-li {
        display: flex;
        align-items: center;
        border: 1px solid rgb(229, 231, 235);
        border-radius: 5px;
        padding: 10px 15px;
        margin: 20px 0;
        font-size: 14px;
        font-weight: 500;
        color: rgb(57, 60, 65);
        line-height: 20px;
        &:hover {
          cursor: pointer;
        }
      }
      .btn {
        width: 300px;
        margin: 30px auto;
        padding: 15px;
        background-color: #12192c;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-weight: 500;
        border-radius: 10px;
        border: 1px solid rgb(229, 231, 235);
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
      .cancel-btn {
        width: 300px;
        margin: 30px auto;
        padding: 15px;
        // background-color: #12192c;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #12192c;
        font-weight: 500;
        border-radius: 10px;
        border: 1px solid #12192c;
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .order-page {
    .order-box {
      width: 100%;
      padding: 0 20px;
      h1 {
        font-size: 22px;
      }
      .table {
        .table-li {
          .content {
            img {
              max-width: 250px;
            }
            video {
              max-width: 250px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .order-page {
    .order-box {
      width: 100%;
      padding: 0 20px;
      h1 {
        font-size: 22px;
      }
      .table {
        .table-li {
          .title {
            width: 150px;
          }
          .content {
            img {
              max-width: 150px;
            }
            video {
              max-width: 150px;
            }
          }
        }
      }
    }
  }
}
