.plan-page {
  overflow: hidden;
  .plan-box {
    width: 1000px;
    margin: 40px auto;
    h1 {
      font-size: 32px;
      line-height: 42px;
      color: rgb(92, 94, 98);
      font-weight: 500;
      margin: 0 0 20px;
      text-align: center;
    }
    p {
      font-size: 18px;
      line-height: 23px;
      color: rgb(57, 60, 65);
      font-weight: 500;
      margin: 10px 0;
    }
    .tip {
      margin: 20px 0;
      color: #ff5c66;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
    }
    .choose-row {
      margin: 30px 0;
      .choose-web {
        display: flex;
        justify-content: center;
        .swiper {
          width: 100%;
          padding-top: 50px;
          padding-bottom: 50px;
          .swiper-slide {
            background-position: center;
            background-size: cover;
            width: 350px;
            background-color: #fff;
            box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.105);
            border-radius: 15px;
            overflow: hidden;
            padding: 20px;
            .img {
              height: 150px;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                max-width: 100%;
                max-height: 100%;
              }
            }
            .little-tip {
              position: absolute;
              bottom: 35px;
              right: -114px;
              width: 100%;
              background-color: #ff5c66;
              padding: 10px;
              transform: rotate(-45deg);
              font-size: 18px;
              color: #fff;
              font-weight: 500;
              text-align: center;
              box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.105);
            }
            .hot {
              position: absolute;
              top: 10px;
              left: 10px;
              display: flex;
              align-items: end;
              span {
                padding: 0 5px;
                font-size: 12px;
                font-weight: 500;
                color: #a0a0a0;
              }
            }

            &:nth-child(2) {
              .little-tip {
                background-color: #ffd700 !important;
              }
            }
            &:nth-child(1) {
              .little-tip {
                background-color: #ff8800 !important;
              }
            }
            &:nth-child(3) {
              .little-tip {
                background-color: #ff0000 !important;
              }
            }

            .title {
              margin: 10px 0 0;
              color: rgb(57, 60, 65);
              font-weight: 650;
              font-size: 18px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              span {
                font-size: 20px;
                color: #ff0000;
                font-weight: 650;
              }
            }
            li {
              margin: 10px 0;
              display: flex;
              align-items: center;
              span {
                padding: 0 8px;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    .mobile-choose-row {
      display: none;
      position: relative;
      .choose-mobile {
        display: flex;
        justify-content: center;
        .swiper {
          width: 350px;
          padding: 25px;
          .swiper-slide {
            background-position: center;
            background-size: cover;
            background-color: #fff;
            box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.105);
            border-radius: 15px;
            overflow: hidden;
            padding: 20px;
            .img {
              height: 150px;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                max-width: 100%;
                max-height: 100%;
              }
            }
            .little-tip {
              position: absolute;
              bottom: 38px;
              right: -100px;
              width: 100%;
              background-color: #ff5c66;
              padding: 10px;
              transform: rotate(-45deg);
              font-size: 14px;
              color: #fff;
              font-weight: 500;
              text-align: center;
              box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.105);
            }
            .hot {
              position: absolute;
              top: 10px;
              left: 10px;
              display: flex;
              align-items: end;
              span {
                padding: 0 5px;
                font-size: 12px;
                font-weight: 500;
                color: #a0a0a0;
              }
            }

            &:nth-child(2) {
              .little-tip {
                background-color: #ffd700 !important;
              }
            }
            &:nth-child(1) {
              .little-tip {
                background-color: #ff8800 !important;
              }
            }
            &:nth-child(3) {
              .little-tip {
                background-color: #ff0000 !important;
              }
            }

            .title {
              margin: 10px 0 0;
              color: rgb(57, 60, 65);
              font-weight: 650;
              font-size: 16px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              span {
                font-size: 16px;
                color: #ff0000;
                font-weight: 650;
              }
            }
            li {
              margin: 10px 0;
              display: flex;
              align-items: center;
              span {
                padding: 0 8px;
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .btn {
      width: 300px;
      margin: 30px auto 0;
      padding: 10px;
      background-color: #12192c;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: 500;
      border-radius: 10px;
      border: 1px solid rgb(229, 231, 235);
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.swiper-3d {
  .swiper-slide-shadow-right {
    background-image: none !important;
  }
  .swiper-slide-shadow-left {
    background-image: none !important;
  }
}

@keyframes move {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}

@media screen and (max-width: 1000px) {
  .plan-page {
    .plan-box {
      width: 100%;
      padding: 0 20px;
      .choose-row {
        display: none !important;
      }
      .mobile-choose-row {
        display: block !important;
      }
    }
  }
}
