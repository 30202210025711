.share-page {
  .bgImage {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
  .share-box {
    position: relative;
    margin: auto;
    width: 320px;
    height: 320px;
    border-radius: 10px;
    overflow: hidden;
    img {
      width: 100%;
    }
    .share-box-contain {
      position: absolute;
      top: 0;
      left: 0;
      padding: 20px;
      width: 100%;
      height: 100%;
      .share-col {
        position: absolute;
        width: 90px;
        height: 90px;
        border-radius: 8px;
        background-image: linear-gradient(#fff, #ffe9da);
        opacity: 0.9;
        top: 20px;
        left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:nth-child(2) {
          transform: translate(95px, 0px);
        }
        &:nth-child(3) {
          transform: translate(190px, 0px);
        }
        &:nth-child(4) {
          transform: translate(190px, 95px);
        }
        &:nth-child(5) {
          transform: translate(190px, 190px);
        }
        &:nth-child(6) {
          transform: translate(95px, 190px);
        }
        &:nth-child(7) {
          transform: translate(0, 190px);
        }
        &:nth-child(8) {
          transform: translate(0, 95px);
        }
        .img-box {
          width: 50px;
          transition: all 0.5s;
          img {
            width: 100%;
          }
        }
        .back-img {
          transform: translateY(190deg);
        }
        .p {
          font-size: 12px;
          text-align: center;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 0 10px;
        }
      }
      .click-btn {
        width: 85px;
        height: 85px;
        background-image: linear-gradient(#ff733e, #ff4e57);
        border: 1px solid #ffa07c;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 7px 2px #fff;
        .up {
          font-size: 14px;
          font-weight: 600;
        }
        .down {
          margin: 5px 0 0;
          font-size: 12px;
          font-weight: 600;
        }
      }
      .share-active {
        opacity: 1;
        box-shadow: 0px 0px 5px 2px #fff;
      }
    }
  }
  .share-title-top {
    margin: 40px 0;
    text-align: center;
    font-size: 35px;
    background: linear-gradient(45deg, #ff5900, #fd9531); /* 定义渐变色 */
    -webkit-background-clip: text; /* 适用于 Webkit 浏览器 */
    -webkit-text-fill-color: transparent; /* 设置文字填充色为透明 */
    background-clip: text; /* 标准化的背景裁剪 */
    font-weight: 650;
  }
  .comment-box {
    .comment-li {
      padding: 10px 20px;
      border-bottom: 1px solid #eee;
      .user-info {
        display: flex;
        justify-content: space-between;
        margin: 0 0 15px;
        align-items: end;
        .left {
          display: flex;
          align-items: center;
          .img {
            width: 40px;
            height: 40px;
            border-radius: 25px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              max-width: 100%;
            }
          }
          .info-txt {
            height: 100%;
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .name {
              font-size: 14px;
              color: #424242;
              margin: 0 0 5px;
              font-weight: 600;
            }
            .time {
              color: #b0b0b0;
              font-size: 12px;
            }
          }
        }
      }
      .back-user {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .comment-content {
        margin: 0 0 15px;
        p {
          font-size: 13px;
          line-height: 20px;
          color: #757575;
          img {
            margin: 10px 0 0;
            max-width: 30%;
          }
        }
      }
      .modelfooter {
        color: #b0b0b0;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .operate {
          display: flex;
          align-items: center;
          .operate-li {
            margin: 0 3px;
          }
        }
      }
    }
  }
  .footer-load {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .animate-load {
      width: 2px;
      height: 10px;
      background-color: #ff6700;
      position: relative;
      transform: scale(1);
      transition: background-color 0.25s ease;
      animation: loader 0.3s alternate-reverse infinite linear;
      &::before {
        content: "";
        top: 1px;
        left: -5px;
        width: 2px;
        height: 8px;
        background-color: #ff6700;
        position: absolute;
        animation: loader 0.25s alternate-reverse infinite linear;
      }
      &::after {
        content: "";
        top: 1px;
        left: 5px;
        width: 2px;
        height: 8px;
        background-color: #ff6700;
        position: absolute;
        animation: loader 0.2s alternate-reverse infinite linear;
      }
    }
  }
}
.draw-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  display: none;
  justify-content: center;
  align-items: center;
  padding: 10px;
  .draw-tip-box {
    width: 100%;
    position: relative;
    img {
      width: 100%;
    }
    .tip-box {
      position: absolute;
      left: 0;
      bottom: 5px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      .tip-txt {
        font-weight: 500;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        font-size: 15px;
      }
      .win-txt {
        color: rgb(239, 68, 68);
      }
      .pro-img {
        height: 60px;
        padding: 5px;
        img {
          max-height: 100%;
        }
      }
      .tip-btn {
        // margin: 10px 20px 0;
        width: 250px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        border-radius: 25px;
        font-size: 15px;
        color: #fff;
        font-weight: 700;
        background-image: linear-gradient(to right, #ffd01e, #ff8917);
      }
    }
  }
}

.shareBox-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  display: none;
  justify-content: center;
  align-items: center;
  .shareBox-contain {
    width: 80%;
    background-color: #fff;
    padding: 25px;
    border-radius: 25px;
    .share-bg {
      width: 200px;
      margin: auto;
      img {
        width: 100%;
      }
    }
    .title {
      text-align: center;
      font-size: 18px;
      margin: 10px 0 0;
    }
    .tip {
      color: #fd000d;
      margin: 10px 0 0;
      text-align: center;
      line-height: 25px;
    }
    .icon-row {
      margin: 20px 0 0;
      display: flex;
      justify-content: center;
    }
    .li-icon {
      width: 150px;
      img {
        width: 100%;
      }
    }
  }
}

.share-tip-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 22;
  .tip-content {
    width: 70%;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    text-align: center;
    line-height: 25px;
    color: #fd000d;
    font-weight: 600;
    position: relative;
  }
}

.closeIcon {
  position: absolute;
  bottom: -80px;
  left: 50%;
  transform: translateX(-50%);
}

.share-tip-show {
  display: flex !important;
}

.draw-mask-show {
  display: flex !important;
}
.shareBox-mask-show {
  display: flex !important;
}

.MuiRating-icon {
  color: #ff6700;
}

@keyframes loader {
  0% {
    opacity: 0.2;
    transform: scaleY(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
  0% {
    opacity: 0.2;
    transform: scaleY(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@media screen and (max-width: 380px) {
  .draw-mask {
    .pro-img {
      height: 45px;
    }
  }
}
