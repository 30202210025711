.info-page {
  overflow: hidden;
  .info-box {
    width: 700px;
    margin: 40px auto;
    h1 {
      font-size: 28px;
      line-height: 42px;
      color: rgb(92, 94, 98);
      font-weight: 500;
      margin: 0 0 5px;
    }
    .p {
      font-size: 14px;
      color: rgb(57, 60, 65);
    }
    .input-row {
      width: 100%;
      height: 42px;
      display: flex;
      align-items: center;
      border: 1px solid rgb(229, 231, 235);
      border-radius: 5px;
      padding: 0 15px;
      margin: 20px 0;
      font-size: 14px;
      font-weight: 500;
      color: rgb(57, 60, 65);
      &:hover {
        cursor: pointer;
      }
    }
    .id-card {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      margin: 30px 0 0;
      .left {
        width: 50%;
        height: 200px;
        border: 1px solid rgb(229, 231, 235);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img {
          width: 200px;
          height: 110px;
        }
        .file-box {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          z-index: 2;
        }
        .img-mask {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          overflow: hidden;
          .size-box {
            position: absolute;
            top: -50%;
            left: -15%;
            width: auto;
            height: auto;
            transform: rotate(45deg);
            li {
              display: flex;
              align-items: center;
              .bwm-icon {
                margin: 15px;
                display: block;
              }
            }
          }
        }
      }
      .right {
        width: 50%;
        height: 200px;
        border: 1px solid rgb(229, 231, 235);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img {
          width: 200px;
          height: 110px;
          opacity: 0.3;
        }
        .file-box {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          z-index: 2;
        }
        .img-mask {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          overflow: hidden;
          .size-box {
            position: absolute;
            top: -50%;
            left: -15%;
            width: auto;
            height: auto;
            transform: rotate(45deg);
            li {
              display: flex;
              align-items: center;
              .bwm-icon {
                margin: 15px;
                display: block;
              }
            }
          }
        }
      }
    }
    .btn {
      width: 300px;
      margin: 30px auto 0;
      padding: 10px;
      background-color: #12192c;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: 500;
      border-radius: 10px;
      border: 1px solid rgb(229, 231, 235);
      opacity: 0.35;
      &:hover {
        cursor: not-allowed;
      }
    }
    .check {
      width: 300px;
      margin: 30px auto 0;
      padding: 10px;
      background-color: #12192c;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: 500;
      border-radius: 10px;
      border: 1px solid rgb(229, 231, 235);
      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .info-page {
    overflow: auto;
    .info-box {
      width: 100%;
      padding: 0 20px;
      h1 {
        font-size: 24px;
      }
      .id-card {
        .left {
          width: 100%;
          img {
            height: auto !important;
          }
        }
        .right {
          width: 100%;
          img {
            height: auto !important;
          }
        }
      }
    }
  }
}
