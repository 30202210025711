.selfieInfo-page {
    overflow: hidden;
    .selfieInfo-box {
        width: 350px;
        margin: 40px auto;
        h2 {
            font-size: 30px;
            font-weight: 500;
            color: #393c42;
            text-align: center;
        }
        .takePhone-box {
            margin: 40px auto;
            display: flex;
            justify-content: center;
            position: relative;
            overflow: hidden;
            .people {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            .animate {
                position: absolute;
                top: 10%;
                left: 2.5%;
                height: 1px;
                width: 95%;
                background: linear-gradient(180deg, rgba(0, 255, 51, 0) 43%, #0351f8 211%);
                animation: move1 1s ease-in-out infinite;
            }
            .video-box {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 4;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .tip {
                    margin: 10px 0;
                    font-size: 16px;
                    font-weight: 600;
                    color: #393c42;
                }
            }
            
        }
        .camera-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 40px 0 0;
                .video-box {
                    width: 300px;
                    // height: 400px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;
                    margin: 10px 0;
                    video{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        vertical-align: middle;
                    }

                    // .receive-video {
                    //     // display: none;;
                    // }
                }
                .remaining{
                    margin: 10px 0;
                    font-weight: 500;
                    text-align: center;
                    font-size: 18px;
                }
                .start{
                    margin: 20px 0 0;
                    background-color: #393c42;
                    width: 300px;
                    padding: 10px 15px;
                    color: #fff;
                    font-weight: 500;
                    text-align: center;
                    border-radius: 15px;
                    &:hover{
                        cursor: pointer;
                    }
                }
                .unClick{
                    margin: 20px 0 0;
                    background-color: #393c42;
                    width: 300px;
                    padding: 10px 15px;
                    color: #fff;
                    font-weight: 500;
                    text-align: center;
                    border-radius: 15px;
                    opacity: .3;
                    &:hover{
                        cursor: not-allowed;
                    }
                }
                .reload{
                    margin: 10px 0;
                    .reload-icon{
                        transition: all .3s;
                        &:hover{
                            cursor: pointer;
                            transform: scale(1.1,1.1);
                        }
                    }
                }
                .btn{
                    margin: 10px 0 0;
                    background-color: #393c42;
                    width: 300px;
                    padding: 10px 15px;
                    color: #fff;
                    font-weight: 500;
                    text-align: center;
                    border-radius: 15px;
                    &:hover{
                        cursor: pointer;
                    }
                }
                
            }
    }
}

@keyframes move1 {
    0% {
        top: 17%;
    }
    100% {
        top: 53%;
        height: 100px;
    }
}

@media screen and (max-width: 370px) {
    .selfieInfo-page {
        overflow: scroll;
        .selfieInfo-box {
            width: 100%;
            padding: 0 20px;
        }
    }
}
