.pop-box {
  display: none;
  position: fixed;
  top: 20px;
  height: auto;
  width: 90%;
  padding: 20px 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #F0FFEF;
  border: 1px solid #027243;
  animation-delay: calc(-1s * var(--i));
  z-index: 20;
  border-radius: 4px;
  color: #027243;
  h4 {
    font-size: 14px;
    display: flex;
    align-items: center;
    p{
      padding: 0 10px;
    }
  }
}

.error{
  border:1px solid #720202 !important;
  background-color: #ffefef !important;
  color: #720202 !important;
}

.pop-show {
  display: block !important;
}

@keyframes animate1 {
  0% {
    transform: translateY(-6.25rem);
  }
  50% {
    transform: translateY(65px);
  }
  75% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(65px);
  }
}

@media screen and (max-width: 700px) {
  .pop-box {
    // left: 16;
  }
}
